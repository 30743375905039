import { graphql } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import { MobileMenu } from "./MobileMenu";
import { Products } from "./Products";
import { Sidebar } from "./Sidebar";
import "./styles.css";

const CatalogCatalog = ({ filters, products }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(
      /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
      )
    );
  }, []);

  // отвечает за отсутствие скролла при изменении параметров url
  /* if (window !== undefined) {
    window.scrollTo = () => {
      debugger;
    };
  } */

  let newProducts = products.map((product) => ({
    ...product,
    valuesForFilterByAdditionalCriteria: [
      product.additionalCriteria.leaderOfSales,
      product.additionalCriteria.newProduct,
      !!product.additionalCriteria.discount,
    ],
  }));

  const [categoryParam, setCategoryParam] = useQueryParam(
    "category",
    StringParam
  );

  const [additionalCriteriaParam, setAdditionalCriteriaParam] = useQueryParam(
    "filter",
    ArrayParam
  );

  let additionalCriteriaParamBoolean;
  if (additionalCriteriaParam) {
    additionalCriteriaParamBoolean = additionalCriteriaParam.map((item) => {
      if (item === "false") {
        return false;
      } else {
        return true;
      }
    });
  }

  const [activePageParam, setActivePageParam] = useQueryParam(
    "activePage",
    NumberParam
  );

  let initialFilterByAdditionalCriteria = [];
  for (
    let i = 0;
    i < Object.keys(newProducts[0].additionalCriteria).length;
    i++
  ) {
    initialFilterByAdditionalCriteria.push(false);
  }

  const [filterByCategories, setFilterByCategories] = useState(
    categoryParam || "Все товары"
  );

  const [filterByAdditionalCriteria, setFilterByAdditionalCriteria] = useState(
    additionalCriteriaParamBoolean || initialFilterByAdditionalCriteria
  );

  const [activePage, setActivePage] = useState(1);

  const handleChangeFilterByCategories = useCallback(
    (newFilterByCategories) => {
      setFilterByCategories(newFilterByCategories);
      setCategoryParam(newFilterByCategories, StringParam);
    },
    [setFilterByCategories, setCategoryParam]
  );

  const handleChangeFilterByAdditionalCriteria = useCallback(
    (index) => {
      let newFilterByAdditionalCriteria = filterByAdditionalCriteria.slice();
      newFilterByAdditionalCriteria[index] = !newFilterByAdditionalCriteria[
        index
      ];
      setFilterByAdditionalCriteria(newFilterByAdditionalCriteria);
      setAdditionalCriteriaParam(newFilterByAdditionalCriteria, ArrayParam);
    },
    [
      setFilterByAdditionalCriteria,
      setAdditionalCriteriaParam,
      filterByAdditionalCriteria,
    ]
  );

  const handleCleanFilterByAdditionalCriteria = useCallback(() => {
    setFilterByAdditionalCriteria([false, false, false]);
    setAdditionalCriteriaParam([false, false, false], ArrayParam);
  }, [setFilterByAdditionalCriteria, setAdditionalCriteriaParam]);

  const handleChangeActivePage = useCallback(
    (activePage) => {
      setActivePage(activePage);
      setActivePageParam(activePage, NumberParam);
    },
    [setActivePage, setActivePageParam]
  );

  const filteredProducts = useMemo(() => {
    return newProducts
      .filter((product) => {
        if (filterByCategories.toLowerCase() === "все товары") return true;
        if (filterByCategories.toLowerCase() === product.category.toLowerCase())
          return true;
        return false;
      })
      .filter((product) => {
        for (let i = 0; i < filterByAdditionalCriteria.length; i++) {
          if (
            filterByAdditionalCriteria[i] === true &&
            product.valuesForFilterByAdditionalCriteria[i] === false
          )
            return false;
        }

        return true;
      });
  }, [filterByCategories, filterByAdditionalCriteria, newProducts]);

  return (
    <div className="CatalogCatalog">
      <div className="[ Content md:Content_medium sm:Content_medium ]">
        <div className="CatalogCatalog__content">
          {isMobile ? (
            <MobileMenu
              {...filters}
              onChangeFilterByCategories={handleChangeFilterByCategories}
              onChangeFilterByAdditionalCriteria={
                handleChangeFilterByAdditionalCriteria
              }
              onCleanFilterByAdditionalCriteria={
                handleCleanFilterByAdditionalCriteria
              }
              filterByCategories={filterByCategories}
              filterByAdditionalCriteria={filterByAdditionalCriteria}
              onChangeActivePage={handleChangeActivePage}
              filteredProducts={filteredProducts}
            />
          ) : (
            <Sidebar
              {...filters}
              onChangeFilterByCategories={handleChangeFilterByCategories}
              onChangeFilterByAdditionalCriteria={
                handleChangeFilterByAdditionalCriteria
              }
              filterByCategories={filterByCategories}
              filterByAdditionalCriteria={filterByAdditionalCriteria}
              onChangeActivePage={handleChangeActivePage}
              filteredProducts={filteredProducts}
            />
          )}
          <Products
            filteredProducts={filteredProducts}
            onChangeActivePage={handleChangeActivePage}
            activePage={activePage}
            isMobile={isMobile}
            onCleanFilterByAdditionalCriteria={
              handleCleanFilterByAdditionalCriteria
            }
            onChangeFilterByCategories={handleChangeFilterByCategories}
          />
        </div>
      </div>
    </div>
  );
};

CatalogCatalog.getLivePreviewData = ({ data, getAsset }) => ({
  filters: {
    ...data.filters,
  },
  products: data.products.map((product) => ({
    ...product,
    photos: product.photos.map((photo) => getAsset(photo).url),
  })),
});

const catalogCatalogQuery = graphql`
  fragment CatalogCatalogFragment on MarkdownRemarkFrontmatter {
    filters {
      nameGroupFilterByCategories
      categories
      nameGroupFilterByAdditionalCriteria
      additionalCriteria
    }
    products {
      name
      slug
      oldPrice
      price
      category
      additionalCriteria {
        leaderOfSales
        newProduct
        discount
      }
      description
      photos {
        childImageSharp {
          fluid(maxWidth: 928) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export { CatalogCatalog, catalogCatalogQuery };
