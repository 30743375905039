import { Link } from "gatsby";
import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const Product = ({ product }) => {
  const {
    photos,
    category,
    name,
    oldPrice,
    price,
    additionalCriteria,
    slug,
  } = product;
  return (
    <li className="Product">
      <Link to={`/catalog/${slug}`}>
        <div className="Product__photoContainer">
          <Image image={photos[0]} className="Product__photo" />
          {additionalCriteria.discount ? (
            <p className="[ Product__discount ] [ Font_smallText Color_white ]">
              {additionalCriteria.discount}
            </p>
          ) : null}
          <div className="[ Product__additionalCriteria ] [ Font_smallText Color_white ]">
            {additionalCriteria.newProduct && (
              <p className="Product__newProduct">Новинка</p>
            )}
            {additionalCriteria.leaderOfSales && (
              <p className="Product__leaderOfSales">Лидер продаж</p>
            )}
          </div>
        </div>
        <p className="[ Product__category ] [ Font_smallestText10 Color_darkBlue48 ]">
          {category}
        </p>
        <p className="[ Product__name ] [ Font_textSemiBold md:Font_smallText sm:Font_smallText Color_darkBlue ]">
          {name}
        </p>
        <div className="Product__prices">
          {oldPrice ? (
            <p className="[ Product__oldPrice ] [ Font_textSemiBold Color_darkBlue20 ]">
              {oldPrice}
            </p>
          ) : null}
          <p className="[ Product__price ] [ Font_headerSmall Color_brightBlue ]">
            {price}
          </p>
        </div>
      </Link>
    </li>
  );
};

export { Product };
