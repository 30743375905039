import cn from "classnames";
import React from "react";
import "./styles.css";

const FilterDropDownMenu = ({ isOpen, children }) => {
  return (
    <ul
      className={cn("FilterDropDownMenu", {
        FilterDropDownMenu_close: !isOpen,
      })}
    >
      {children}
    </ul>
  );
};

export { FilterDropDownMenu };
