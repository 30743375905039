import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { ArrowMenu } from "../../../../icons/ArrowMenu";
import { FilterDropDownMenu } from "../FilterDropDownMenu";
import "./styles.css";

const FilterByCategories = ({
  className,
  categories,
  filterByCategories,
  onChangeFilterByCategories,
  onChangeActivePage,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(
    () => setIsOpenMenu((isOpenMenu) => !isOpenMenu),
    [setIsOpenMenu]
  );

  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), [
    setIsOpenMenu,
  ]);

  const menu = useRef(null);
  useOnClickOutside(menu, handleCloseMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMenu, { passive: true });
  });

  return (
    <div className={className} ref={menu}>
      <button
        className={cn(
          "[ FilterByCategories__header ] [ Color_darkBlue80 Font_smallText ]",
          {
            FilterByCategories__headerOpen: isOpenMenu,
          }
        )}
        onClick={handleToggleMenu}
      >
        {filterByCategories}
        <ArrowMenu
          className={cn("FilterByCategories__headerIcon", {
            FilterByCategories__headerIconOpenMenu: isOpenMenu,
          })}
        />
      </button>
      <FilterDropDownMenu isOpen={isOpenMenu} onClose={handleCloseMenu}>
        {categories
          .filter((category) => category !== filterByCategories)
          .map((category) => (
            <li
              className="FilterDropDownMenu__categoryContainer"
              onClick={() => {
                handleCloseMenu();
                onChangeFilterByCategories(category);
                onChangeActivePage(1);
              }}
              key={category}
            >
              <button className="[ FilterDropDownMenu__category ] [ Color_darkBlue80 Font_smallText ]">
                {category}
              </button>
            </li>
          ))}
      </FilterDropDownMenu>
    </div>
  );
};

export { FilterByCategories };
