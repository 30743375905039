import cn from "classnames";
import React from "react";
import { Checkbox } from "../../Checkbox";
import "./styles.css";

const Sidebar = ({
  nameGroupFilterByCategories,
  categories,
  nameGroupFilterByAdditionalCriteria,
  additionalCriteria,
  onChangeFilterByAdditionalCriteria,
  onChangeFilterByCategories,
  filterByCategories,
  filteredProducts,
  onChangeActivePage,
  filterByAdditionalCriteria,
}) => {
  additionalCriteria = [
    {
      criterion: additionalCriteria[0],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.leaderOfSales
      ).length,
    },
    {
      criterion: additionalCriteria[1],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.newProduct
      ).length,
    },
    {
      criterion: additionalCriteria[2],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.discount
      ).length,
    },
  ];

  return (
    <div className="Sidebar">
      <p className="[ Sidebar__filterGroupName ] [ Font_smallText Color_darkBlue48 ]">
        {nameGroupFilterByCategories}
      </p>
      <ul className="[ Sidebar_categoryFilter ]">
        {categories.map((category) => (
          <li
            className={cn(
              "[ Sidebar_categoryFilter__item ] [ Font_textSemiBold Color_darkBlue80 ]",
              {
                Sidebar_categoryFilter__itemActive:
                  category === filterByCategories,
                Color_white: category === filterByCategories,
              }
            )}
            key={category}
            onClick={() => {
              onChangeFilterByCategories(category);
              onChangeActivePage(1);
            }}
          >
            {category}
          </li>
        ))}
      </ul>
      <p className="[ Sidebar__filterGroupName ] [ Font_smallText Color_darkBlue48 ]">
        {nameGroupFilterByAdditionalCriteria}
      </p>

      <ul className="[ Sidebar_tagFilter ]">
        {additionalCriteria.map(({ criterion, amount }, index) => (
          <li
            className="[ Sidebar_tagFilter__item ] [ Font_smallText Color_darkBlue80 ]"
            key={criterion}
          >
            <Checkbox
              onChange={(e) => {
                onChangeFilterByAdditionalCriteria(index, e.target.checked);
                onChangeActivePage(1);
              }}
              checked={filterByAdditionalCriteria[index]}
            >
              <span>{criterion}</span>
              <span className=" [ Sidebar_tagFilter__amount ] [ Color_darkBlue48 ]">
                {amount}
              </span>
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Sidebar };
