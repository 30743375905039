import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { ArrowMenu } from "../../../../icons/ArrowMenu";
import { Checkbox } from "../../../Checkbox";
import { FilterDropDownMenu } from "../FilterDropDownMenu";
import "./styles.css";

const FilterByAdditionalCriteria = ({
  className,
  additionalCriteria,
  filterByAdditionalCriteria,
  onChangeFilterByAdditionalCriteria,
  filteredProducts,
  onChangeActivePage,
}) => {
  additionalCriteria = [
    {
      criterion: additionalCriteria[0],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.leaderOfSales
      ).length,
    },
    {
      criterion: additionalCriteria[1],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.newProduct
      ).length,
    },
    {
      criterion: additionalCriteria[2],
      amount: filteredProducts.filter(
        (product) => product.additionalCriteria.discount
      ).length,
    },
  ];

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(
    () => setIsOpenMenu((isOpenMenu) => !isOpenMenu),
    [setIsOpenMenu]
  );

  const handleCloseMenu = useCallback(() => setIsOpenMenu(false), [
    setIsOpenMenu,
  ]);

  const menu = useRef(null);
  useOnClickOutside(menu, handleCloseMenu);

  useEffect(() => {
    window.addEventListener("scroll", handleCloseMenu, { passive: true });
  });

  return (
    <div className={className} ref={menu}>
      <button
        className={cn(
          "[ FilterByAdditionalCriteria__header ] [ Color_darkBlue80 Font_smallText ]",
          {
            FilterByCategories__headerOpen: isOpenMenu,
          }
        )}
        onClick={handleToggleMenu}
      >
        <span>
          <span>Фильтр</span>
          <span>
            {filterByAdditionalCriteria.includes(true) ? (
              <span className="FilterByAdditionalCriteria__headerLength">
                {`(${
                  filterByAdditionalCriteria.filter((criterion) => !!criterion)
                    .length
                })`}
              </span>
            ) : null}
          </span>
        </span>

        <ArrowMenu
          className={cn("FilterByAdditionalCriteria__headerIcon", {
            FilterByCategories__headerIconOpenMenu: isOpenMenu,
          })}
        />
      </button>
      <FilterDropDownMenu isOpen={isOpenMenu} onClose={handleCloseMenu}>
        {additionalCriteria.map(({ criterion, amount }, index) => (
          <li
            className="FilterDropDownMenu__criterionContainer"
            key={criterion}
          >
            <button className="[ FilterDropDownMenu__criterion ] [ Color_darkBlue80 Font_smallText ]">
              <Checkbox
                onChange={() => {
                  onChangeFilterByAdditionalCriteria(index);
                  onChangeActivePage(1);
                }}
                checked={filterByAdditionalCriteria[index]}
              >
                <span>{criterion}</span>
                <span className="[ FilterByAdditionalCriteria__amount ] [ Color_darkBlue48 ]">
                  {amount}
                </span>
              </Checkbox>
            </button>
          </li>
        ))}
      </FilterDropDownMenu>
    </div>
  );
};

export { FilterByAdditionalCriteria };
