import React from "react";
import { ActiveFilters } from "./ActiveFilters";
import { FilterByAdditionalCriteria } from "./FilterByAdditionalCriteria";
import { FilterByCategories } from "./FilterByCategories";
import "./styles.css";

const MobileMenu = ({
  categories,
  additionalCriteria,
  onChangeFilterByAdditionalCriteria,
  onChangeFilterByCategories,
  filterByCategories,
  filterByAdditionalCriteria,
  filteredProducts,
  onCleanFilterByAdditionalCriteria,
  onChangeActivePage,
}) => {
  return (
    <div>
      <FilterByCategories
        className="FilterByCategories"
        categories={categories}
        filterByCategories={filterByCategories}
        onChangeFilterByCategories={onChangeFilterByCategories}
        onChangeActivePage={onChangeActivePage}
      />
      <FilterByAdditionalCriteria
        className="FilterByAdditionalCriteria"
        additionalCriteria={additionalCriteria}
        filterByAdditionalCriteria={filterByAdditionalCriteria}
        onChangeFilterByAdditionalCriteria={onChangeFilterByAdditionalCriteria}
        filteredProducts={filteredProducts}
        onChangeActivePage={onChangeActivePage}
      />
      <ActiveFilters
        className="ActiveFilters"
        filterByAdditionalCriteria={filterByAdditionalCriteria}
        onChangeFilterByAdditionalCriteria={onChangeFilterByAdditionalCriteria}
        additionalCriteria={additionalCriteria}
        onCleanFilterByAdditionalCriteria={onCleanFilterByAdditionalCriteria}
        onChangeActivePage={onChangeActivePage}
        onChangeFilterByCategories={onChangeFilterByCategories}
      />
    </div>
  );
};

export { MobileMenu };
