import cn from "classnames";
import React from "react";
import { ArrowCatalog } from "../../../icons/ArrowCatalog";
import { NotFound } from "../NotFound";
import { Product } from "../Product";
import "./styles.css";

const Products = ({
  filteredProducts,
  activePage,
  onChangeActivePage,
  isMobile,
  onCleanFilterByAdditionalCriteria,
  onChangeFilterByCategories,
}) => {
  let numberOfProductsOnPage;
  if (isMobile) {
    numberOfProductsOnPage = 6;
  } else numberOfProductsOnPage = 12;

  let pageNumbers = [];

  for (
    let i = 0;
    i < Math.ceil(filteredProducts.length / numberOfProductsOnPage);
    i++
  ) {
    pageNumbers.push(i + 1);
  }

  return (
    <div className="Products__wrapper">
      <ul className="Products">
        {filteredProducts
          .filter(
            (product, index) =>
              index >= (activePage - 1) * numberOfProductsOnPage &&
              index < activePage * numberOfProductsOnPage
          )
          .map((product, index) => (
            <Product
              product={product}
              products={filteredProducts}
              key={index}
            />
          ))}
      </ul>
      {filteredProducts.length ? (
        <ul className="Products__pageSelector">
          <li
            onClick={() => {
              if (activePage === 1) return;
              onChangeActivePage((activePage) => activePage - 1);
            }}
          >
            <ArrowCatalog
              className={cn("Products__arrow Products__arrowPrev", {
                Products__arrowDisabled: activePage === 1,
              })}
            />
          </li>
          {pageNumbers
            .filter((pageNumber) => {
              if (activePage === 1 && pageNumber - activePage <= 4) return true;
              if (activePage === 2 && pageNumber - activePage <= 3) return true;
              if (
                activePage === pageNumbers[pageNumbers.length - 1] &&
                activePage - pageNumber <= 4
              )
                return true;
              if (
                activePage === pageNumbers[pageNumbers.length - 2] &&
                activePage - pageNumber <= 3
              )
                return true;
              if (
                pageNumber - activePage <= 2 &&
                pageNumber - activePage >= -2
              ) {
                return true;
              }
              return false;
            })
            .map((pageNumber) => (
              <li
                key={pageNumber}
                onClick={() => onChangeActivePage(pageNumber)}
                className={cn(
                  "[ Products__pageNumber ] [ Font_textSemiBold Color_darkBlue48 ]",
                  {
                    Products__activePageNumber: pageNumber === activePage,
                  }
                )}
              >
                {pageNumber}
              </li>
            ))}
          <li
            onClick={() => {
              if (activePage === pageNumbers[pageNumbers.length - 1]) return;
              onChangeActivePage((activePage) => activePage + 1);
            }}
          >
            <ArrowCatalog
              className={cn("Products__arrow Products__arrowNext", {
                Products__arrowDisabled:
                  activePage === pageNumbers[pageNumbers.length - 1],
              })}
            />
          </li>
        </ul>
      ) : (
        <NotFound
          className="Products__notFound"
          onCleanFilterByAdditionalCriteria={onCleanFilterByAdditionalCriteria}
          onChangeFilterByCategories={onChangeFilterByCategories}
        />
      )}
    </div>
  );
};

export { Products };
