import React from "react";
import { NotFoundIcon } from "../../../icons/NotFoundIcon";
import "./styles.css";

const NotFound = ({
  className,
  onCleanFilterByAdditionalCriteria,
  onChangeFilterByCategories,
}) => {
  return (
    <div className={`[ ${className} NotFound ]`}>
      <div className=" [ NotFound__content ] ">
        <NotFoundIcon className="NotFound__icon" />
        <p className="[ NotFound__message ] [ Font_textSemiBold Color_darkBlue48 sm:Font_smallText ]">
          Упс! У нас нет таких товаров, попробуйте изменить условия поиска.
        </p>
        <button
          className="[ NotFound__button ] [ Color_brightBlue Font_textSemiBold ]"
          onClick={() => {
            onCleanFilterByAdditionalCriteria();
            onChangeFilterByCategories("Все товары");
          }}
        >
          Сбросить фильтры
        </button>
      </div>
    </div>
  );
};

export { NotFound };
