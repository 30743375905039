import React from "react";

const ArrowCatalog = (props) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 12 9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.3048 0L6.30869 0.954696L9.34371 3.86354H0V5.13646H9.34371L6.30869 8.0453L7.3048 9L12 4.5L7.3048 0Z" />
    </svg>
  );
};

export { ArrowCatalog };
