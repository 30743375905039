import React from "react";
import { Close } from "../../../../icons/Close";
import "./styles.css";

const ActiveFilters = ({
  className,
  filterByAdditionalCriteria,
  onChangeFilterByAdditionalCriteria,
  onCleanFilterByAdditionalCriteria,
  additionalCriteria,
  onChangeActivePage,
  onChangeFilterByCategories,
}) => {
  return (
    <>
      {filterByAdditionalCriteria.includes(true) ? (
        <ul className={className}>
          {additionalCriteria
            .map((criterion, index) => (
              <li
                className="[ ActiveFilter__criterion ] [ Font_smallestText Color_darkBlue ]"
                onClick={() => {
                  onChangeFilterByAdditionalCriteria(index);
                  onChangeActivePage(1);
                }}
                key={index}
              >
                <div>
                  <Close className="ActiveFilter__criterionIconClose" />
                  <span className="ActiveFilter__criterionText">
                    {criterion}
                  </span>
                </div>
              </li>
            ))
            .filter((criterion, index) => filterByAdditionalCriteria[index])}
          {filterByAdditionalCriteria.includes(true) ? (
            <li
              className="[ ActiveFilter__cleanFilters ] [ Font_smallestText Color_darkBlue ]"
              onClick={() => {
                onCleanFilterByAdditionalCriteria();
                onChangeFilterByCategories("Все товары");
                onChangeActivePage(1);
              }}
            >
              <div>
                <Close className="ActiveFilter__criterionIconClose" />
                <span className="ActiveFilter__cleanFiltersText">
                  Сбросить всё
                </span>
              </div>
            </li>
          ) : null}
        </ul>
      ) : null}
    </>
  );
};

export { ActiveFilters };
