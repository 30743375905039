import React from "react";
import { Choose } from "../../icons/Choose";
import "./styles.css";

const Checkbox = ({ children, ...props }) => {
  return (
    <label className="Checkbox">
      <input className="Checkbox__input" type="checkbox" {...props}></input>
      <span className="Checkbox__iconWrapper">
        <Choose className="Checkbox__icon" />
      </span>
      <span className="[ Checkbox__text ] [ text__regular ]">{children}</span>
    </label>
  );
};

export { Checkbox };
