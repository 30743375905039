import React from "react";

const NotFoundIcon = (props) => {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M47.5418 40.1639L26.9264 4.45696C26.3156 3.39899 25.2217 2.7674 24 2.7674C22.7783 2.7674 21.6844 3.39899 21.0736 4.45696L0.458156 40.1639C-0.152719 41.2219 -0.152719 42.4851 0.458156 43.543C1.06894 44.601 2.16291 45.2326 3.38456 45.2326H44.6154C45.8371 45.2326 46.9311 44.601 47.5418 43.543C48.1527 42.4851 48.1527 41.2219 47.5418 40.1639ZM45.1032 42.1351C45.0426 42.2403 44.8983 42.4167 44.6155 42.4167H3.38456C3.10163 42.4167 2.95744 42.2404 2.89687 42.1351C2.83622 42.03 2.75541 41.8169 2.89687 41.5719L23.5122 5.86489C23.6537 5.61993 23.8785 5.58327 23.9999 5.58327C24.1214 5.58327 24.3462 5.61983 24.4876 5.86489L45.1031 41.5719C45.2447 41.8169 45.1639 42.03 45.1032 42.1351Z"
          fill="#194068"
          fillOpacity="0.48"
        />
        <path
          d="M25.4083 15.5715H22.5923V30.5899H25.4083V15.5715Z"
          fill="#194068"
          fillOpacity="0.48"
        />
        <path
          d="M23.9999 37.1604C25.0367 37.1604 25.8773 36.3199 25.8773 35.2831C25.8773 34.2463 25.0367 33.4058 23.9999 33.4058C22.9631 33.4058 22.1226 34.2463 22.1226 35.2831C22.1226 36.3199 22.9631 37.1604 23.9999 37.1604Z"
          fill="#194068"
          fillOpacity="0.48"
        />
      </g>
    </svg>
  );
};

export { NotFoundIcon };
